import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import H1Category from "../../components/h1Category"
import ContentPage from "../../layouts/contentPage"
import ProductBox from "../../components/productBox"
import ProductBoxCta from "../../components/productBox/productBoxCta"
import ProducstList from "../../components/productsList"
import Seo from "../../components/seo"

import ArrowRight from "../../images/arrow-right.svg"
import ProductBanner from "../../components/productBanner"
import Container from "../../components/Product/container"





const Category = () => (
    <Layout>
      <Seo title="Linia Acid Peel" />
      <ContentPage>
            <H1Category color="blue">Linia Acid Peel</H1Category>
            <ProducstList>

                <ProductBox data-color="blue">
                    <Link to="/produkty/przeciwstarzeniowy-peeling">
                        <StaticImage
                        src="../../images/media/products/acid/category/przeciwstarzeniowy-peeling.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Przeciwstarzeniowy peeling kwasowy"
                        />
                        <ProductBoxCta className="cta" data-color="blue">
                            <div>
                                <h3>Przeciwstarzeniowy
                                <br />peeling kwasowy</h3>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>

                <ProductBox data-color="blue">
                    <Link to="/produkty/odnawiajacy-peeling">
                        <StaticImage
                        src="../../images/media/products/acid/category/odnawiajacy-peeling.png"
                        loading="eager"
                        // width={446}
                        quality={95}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="Odnawiający peeling kwasowy"
                        />
                        <ProductBoxCta className="cta" data-color="blue">
                            <div>
                            <h3>Odnawiający 
                            <br />peeling kwasowy</h3>
                            </div>
                            <ArrowRight/>
                        </ProductBoxCta>
                    </Link>
                </ProductBox>
                <ProductBanner productLine="acid"/>


          </ProducstList>
      </ContentPage>
    </Layout>
)

export default Category
